.not-found-container {
    text-align: center;
    margin-top: 50px;
}

.not-found-container h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.not-found-container p {
    font-size: 1.5rem;
    margin-bottom: 30px;
}

.not-found-container .btn {
    padding: 10px 20px;
    font-size: 1.25rem;
}
